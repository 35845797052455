import { useCallback, useRef } from 'react'

export const useDebouncer = <CB extends CallableFunction>(
  callback: CB,
  timeout?: number
): CB => {
  const debouncer = useRef<NodeJS.Timeout | null>(null)
  const timer = timeout || 400

  const returnedCallback = useCallback(
    (...params: unknown[]) => {
      if (debouncer.current) {
        clearTimeout(debouncer.current)
      }

      debouncer.current = setTimeout(() => {
        callback(...params)
      }, timer)
    },
    [timer, callback]
  )

  return returnedCallback as unknown as CB
}
