import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { Socket } from 'socket.io-client'
import { useDebounce } from 'use-debounce'

import {
  Conversation,
  ConversationEventDirection,
  WebSocketPageEvent,
  ArchiveOrMarkSpamConversationMutationParams,
  useArchiveOrMarkSpamConversation,
  MarkReadConversationMutationParams,
  useMarkReadConversation,
  useGetConversations,
} from 'src/api'
import useAuthContext from 'src/contexts/AuthContext'
import { useWebsockets } from 'src/contexts/ConversationsListContext/hooks'
import {
  playNotificationAudio,
  createZeroState,
} from 'src/contexts/ConversationsListContext/utils'
import { useLocationContext } from 'src/contexts/LocationContext'
import { ModalNotificationsContextProvider } from 'src/contexts/ModalNotificationsContext'
import useNavigationInterceptorContext from 'src/contexts/NavigationInterceptor'
import { EmptyFn, EmptyPromiseFn } from 'src/utils/interfaces'
import logger from 'src/utils/logger'

export interface ConversationsContextType {
  conversations: Conversation[]
  /** Search string to filter the conversation string */
  conversationListFilter: string
  /** Flag use to prevent updates in the UI when there are requests in flight */
  preventUiUpdates: boolean
  isLoading: boolean
  socket?: Socket
  openConversationId?: number
  hasMoreConversations: boolean
  getMoreConversations: () => Promise<void>
  getConversation: (id?: number) => Conversation | undefined
  mutateConversationRead: (
    params: MarkReadConversationMutationParams
  ) => Promise<void>
  mutateConversationArchived: (
    params: ArchiveOrMarkSpamConversationMutationParams
  ) => Promise<void>
  setPreventUiUpdates: Dispatch<SetStateAction<boolean>>
  setConversationListFilter: (filter: string) => void
  refetch: () => Promise<void>
  setOpenConversationId: (conversationId?: number) => void
}

const ConversationsListContext = createContext<ConversationsContextType>({
  conversations: [],
  conversationListFilter: '',
  preventUiUpdates: false,
  socket: undefined,
  isLoading: false,
  openConversationId: undefined,
  hasMoreConversations: false,
  getMoreConversations: EmptyPromiseFn,
  getConversation: () => undefined,
  mutateConversationRead: EmptyPromiseFn,
  mutateConversationArchived: EmptyPromiseFn,
  setPreventUiUpdates: EmptyFn,
  setConversationListFilter: EmptyFn,
  refetch: EmptyPromiseFn,
  setOpenConversationId: EmptyFn,
})

export const ConversationsContextProvider: React.FCWithChildren = ({
  children,
}) => {
  // Hooks initialization
  const { user } = useAuthContext()
  const { search } = useLocation()
  const { locationId } = useLocationContext()
  const { pageTitle } = useNavigationInterceptorContext()
  const { socket, join } = useWebsockets()

  // useRefs
  const notificationAudio = useRef(new Audio('/message-notification.mp3'))
  const initialCountIsSet = useRef(false)
  const hasChangedFromInitialValue = useRef(false)
  const initialUnreadConversationsCount = useRef(0)

  // useStates
  const [conversationListFilter, setConversationListFilter] = useState('')
  const [openConversationId, setOpenConversationId] = useState(() => {
    const searchParam = new URLSearchParams(search).get('open-conversation')

    return !!searchParam ? parseInt(searchParam) : undefined
  })

  // preventUiUpdates should be used to prevent the browser from doing UI Updates
  // when there are requests in flight. e.g. a messaging being sent
  // OBS: useTransition should be used instead
  const [preventUiUpdates, setPreventUiUpdates] = useState(false)

  const [debouncedFilter] = useDebounce(conversationListFilter, 1000)
  const activeTab = new URLSearchParams(search).get('active-tab')
  const statusId = +(activeTab ?? '').split('-')[1]

  const {
    data: conversationsQuery,
    isLoading: conversationsIsLoading,
    refetch: refetchConversations,
    hasNextPage,
    fetchNextPage,
  } = useGetConversations({
    locationId,
    search: debouncedFilter,
    isSpam: activeTab === 'spam',
    isArchived: activeTab === 'archived',
    statusId: !isNaN(statusId) ? statusId : undefined,
  })

  const { mutateAsync: mutateConversationRead } =
    useMarkReadConversation(locationId)
  const { mutateAsync: mutateConversationArchived } =
    useArchiveOrMarkSpamConversation(locationId)

  const updateSiteIcon = (hasNewMessages: boolean) => {
    const faviconPath = hasNewMessages
      ? '/favicon-new-message.ico'
      : '/favicon.ico'

    const link: HTMLLinkElement | null =
      document.querySelector('#siteFavicon') || null

    if (link) {
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = faviconPath
      document.getElementsByTagName('head')[0].appendChild(link)
    }
  }

  const isLoading = useMemo(
    () => conversationsIsLoading,
    [conversationsIsLoading]
  )

  const conversationsList = useMemo(() => {
    const list =
      conversationsQuery?.pages.reduce<Conversation[]>(
        (acc, page) => acc.concat(page.data),
        []
      ) ?? []

    const zeroState = createZeroState(user.firstName ?? '')

    return [zeroState].concat(...list)
  }, [conversationsQuery?.pages, user.firstName])

  // useEffects
  /**
   * WebSocket handling: connection, new data to fetch listener.
   */
  useEffect(() => {
    join({ userId: user.id, locationId })

    const onPage = async (events: WebSocketPageEvent) => {
      logger.debug('New page events received', { events })

      let shouldPlayNotificationAudio = false

      const { data: result } = await refetchConversations()
      const firstPage = result?.pages[0].data

      if (firstPage?.length) {
        const conversations = events.map(({ conversationId }) => conversationId)
        const newConversations = firstPage.filter((c) =>
          conversations.includes(c.contactId)
        )

        shouldPlayNotificationAudio = newConversations.some(
          (c) =>
            c.mostRecentEvent.direction === ConversationEventDirection.INCOMING
        )
      }

      if (shouldPlayNotificationAudio) {
        void playNotificationAudio(notificationAudio.current)
      }
    }

    socket.on('page', onPage)

    return () => void socket.off('page', onPage)
  }, [join, locationId, refetchConversations, socket, user.id])

  /**
   * `initialCountIsSet` initialization
   */
  useEffect(() => {
    if (
      !initialCountIsSet.current &&
      conversationsIsLoading &&
      conversationsList.length !== -1
    ) {
      initialUnreadConversationsCount.current = conversationsList.filter(
        (c) => !c.mostRecentEvent.isRead
      ).length
      initialCountIsSet.current = true
    }
  }, [conversationsList, conversationsIsLoading])

  /**
   * New message notification handling
   */
  useEffect(() => {
    if (initialCountIsSet.current && !hasChangedFromInitialValue.current) {
      const initial = initialUnreadConversationsCount.current
      const current = conversationsList.filter(
        (c) => !c.mostRecentEvent.isRead
      ).length

      if (initial !== current) {
        hasChangedFromInitialValue.current = true
      }
    }
  }, [conversationsList])

  /**
   * Document title update handling
   */
  useEffect(() => {
    let alertInterval: NodeJS.Timeout

    if (initialCountIsSet.current) {
      const hasUnreadMessages = conversationsList.some(
        (c) => !c.mostRecentEvent.isRead
      )
      const unreadConvosCount = conversationsList.filter(
        (c) => !c.mostRecentEvent.isRead
      ).length

      if (hasUnreadMessages && hasChangedFromInitialValue.current) {
        alertInterval = setInterval(() => {
          if (document.title === pageTitle) {
            document.title = `${unreadConvosCount} unread ${
              unreadConvosCount > 1 ? 'conversations' : 'conversation'
            }`
            updateSiteIcon(unreadConvosCount > 0)
          } else {
            document.title = pageTitle
            updateSiteIcon(false)
          }
        }, 1000)
      } else {
        document.title = pageTitle
      }

      updateSiteIcon(hasUnreadMessages && hasChangedFromInitialValue.current)
    } else {
      document.title = pageTitle
    }

    return () => {
      clearInterval(alertInterval)
      document.title = 'Signpost'
    }
  }, [conversationsList, pageTitle])

  const getConversation = useCallback(
    (id?: number) => conversationsList.find((c) => c.id === id),
    [conversationsList]
  )

  const refetch = useCallback(async () => {
    await refetchConversations()
  }, [refetchConversations])

  const contextValue: ConversationsContextType = {
    conversations: conversationsList,
    conversationListFilter,
    preventUiUpdates,
    isLoading,
    socket,
    openConversationId,
    hasMoreConversations: hasNextPage,
    getMoreConversations: async () => {
      await fetchNextPage()
    },
    getConversation,
    mutateConversationRead,
    mutateConversationArchived,
    setPreventUiUpdates,
    setConversationListFilter,
    refetch,
    setOpenConversationId,
  }

  return (
    <ConversationsListContext.Provider value={contextValue}>
      <ModalNotificationsContextProvider>
        {children}
      </ModalNotificationsContextProvider>
    </ConversationsListContext.Provider>
  )
}

/**
 * Use this hook to use data and methods to manipulate the **conversations list**
 */
const useConversationsListContext = () => useContext(ConversationsListContext)

export default useConversationsListContext
