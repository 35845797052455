import React, {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'

import { useGetLocationFeatureFlag, SmsRegistrationStatus } from 'src/api'
import Attachments from 'src/components/MessagingHub/MessagesPane/Attachments'
import {
  AttachmentsContainer,
  HelperButtonsSection,
  MessageSendContainer,
  MessageSendFooter,
  StyledContainer,
  StyledErrorMessage,
  StyledPaperClipIcon,
  StyledTextbox,
} from 'src/components/MessagingHub/MessagesPane/MessageSend/styled'
import { CalculateMessageBoxHeight } from 'src/components/MessagingHub/MessagesPane/index'
import { useShowPhoneCallModal } from 'src/components/MessagingHub/PhoneCallModal/hooks'
import { getTCPAModalParams } from 'src/components/MessagingHub/TCPAModal'
import { useShowUpsellModal } from 'src/components/MessagingHub/UpsellModal/hooks'
// import { hasAcceptedTCPA } from 'src/components/MessagingHub/utils'
import {
  ActiveConversationMessages,
  StoreActiveConversationParams,
} from 'src/containers/MessagingHub/types'
import useAuthContext from 'src/contexts/AuthContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import useMhContext from 'src/contexts/MhContext'
import { HandleSendMessage } from 'src/contexts/MhContext/types'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import Constants from 'src/lib/Constants'
import { Button, ButtonProps } from 'src/stories/Button'
import Tooltip from 'src/stories/Tooltip'
import {
  CheckmarkIcon,
  PhoneIcon,
  QuestionIcon,
  VCardIcon,
} from 'src/stories/assets'
import { Body } from 'src/stories/typography'
import { generateLocationUrl } from 'src/utils'

const StyledQuestionIcon = styled(QuestionIcon)(({ theme }) => ({
  cursor: 'pointer',
  // Using !important since the button has a style
  // targeting svg (which normally will go at the beginning)
  marginRight: '0 !important',
}))

const ButtonLabelWithTooltip: React.FC<{
  label: string
  helperText: string
}> = ({ helperText, label }) => {
  const theme = useTheme()

  return (
    <>
      {label}
      <Tooltip
        text={helperText}
        fontSize="1.2rem"
        color={theme.colors.base_60}
        style={{
          marginLeft: theme.space(2),
          whiteSpace: 'pre-wrap',
        }}
        fontWeight={400}
        widthUnits={60}
      >
        <StyledQuestionIcon fill={theme.colors.base_50} />
      </Tooltip>
    </>
  )
}

const getMessageSendButtonProps = (
  theme: DefaultTheme,
  locationId: number,
  onSendClick: () => void,
  isTextingEnabled = false,
  isFacebookTextingEnabled = false,
  registrationStatus?: SmsRegistrationStatus
): ButtonProps => {
  if (isFacebookTextingEnabled) {
    return {
      label: 'Send',
      onClick: onSendClick,
      maxWidth: theme.space(20),
    }
  }

  if (!isTextingEnabled) {
    return {
      label: 'Contact Customer Service to Enable Texting',
      action: 'confirm',
      maxWidth: 'unset',
      href: Constants.links.enableTextingBooking,
      shouldOpenInNewTab: true,
    }
  }

  switch (registrationStatus) {
    case SmsRegistrationStatus.IN_PROGRESS:
      return {
        label: (
          <ButtonLabelWithTooltip
            label="Registration pending"
            helperText="Your SMS Registration is pending approval, which usually takes a couple of days. Once approved, you can start texting customers without carrier restrictions."
          />
        ),
        action: 'secondary',
        maxWidth: theme.space(48),
        outline: true,
        disabled: true,
        style: {
          fontStyle: 'italic',
          cursor: 'default',
        },
      }

    case SmsRegistrationStatus.FAILED:
      return {
        label: (
          <ButtonLabelWithTooltip
            label="Registration not approved"
            helperText="Your SMS Registration was not approved. Please contact Signpost support at (844) 202-2015 for assistance."
          />
        ),
        action: 'danger',
        outline: true,
        onClick: onSendClick,
        maxWidth: theme.space(59),
        style: {
          fontStyle: 'italic',
          cursor: 'default',
        },
      }

    case SmsRegistrationStatus.APPROVED:
      return {
        label: 'Send',
        onClick: onSendClick,
        maxWidth: theme.space(20),
      }
    default:
      return {
        label: 'Register to start texting',
        action: 'confirm',
        maxWidth: theme.space(45),
        href: generateLocationUrl(
          locationId,
          '/settings/business/sms-registration'
        ),
      }
  }
}

interface MessageSendProps {
  onSend: HandleSendMessage
  onAttach: () => void
  storeActiveConversation?: (params: StoreActiveConversationParams) => void
  calculateHeight?: CalculateMessageBoxHeight
  activeConversations?: ActiveConversationMessages
  errorMessage?: string
  textAreaRef?: React.RefObject<HTMLTextAreaElement>
  forceDisable?: boolean
  withMargins?: boolean
  height?: number
  dayaCyUniquePrefix?: string
  onFocusTextBox?: (event: FocusEvent<HTMLTextAreaElement>) => void
  onChangeTextBox?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  needsPolicyAcknowledgement?: boolean
  isInModal?: boolean
}

const MessageSend: React.FC<MessageSendProps> = ({
  activeConversations = {},
  errorMessage,
  textAreaRef,
  forceDisable,
  withMargins = true,
  height,
  onSend,
  onAttach,
  storeActiveConversation,
  calculateHeight = () => null,
  dayaCyUniquePrefix,
  onFocusTextBox,
  onChangeTextBox,
  isInModal,
}) => {
  const [isSending, setIsSending] = useState(false)
  const [sendVCard, setSendVCard] = useState(false)
  const { refetchUser } = useAuthContext()
  const { user } = useAuthContext()
  const { locationId, activeLocation } = useLocationContext()
  const showUpsellModal = useShowUpsellModal()
  const { openConversationId } = useMhContext()
  const showPhoneCallModal = useShowPhoneCallModal()

  const {
    isLargeDesktop,
    isDesktop,
    isExtraLargeDesktop,
    openConversationContactDetails,
  } = useMhContext()

  const { data: textingEnabledFeatureFlag } = useGetLocationFeatureFlag({
    locationId,
    featureFlagName: Constants.FeatureFlags.isTextingEnabled,
    defaultValue: 0,
  })
  const isTextingEnabled = textingEnabledFeatureFlag.value === 1

  const { data: fbTextingEnabledFeatureFlag } = useGetLocationFeatureFlag({
    locationId,
    featureFlagName: Constants.FeatureFlags.isFacebookTextingEnabled,
    defaultValue: 0,
  })
  const isFacebookTextingEnabled = fbTextingEnabledFeatureFlag.value === 1

  const hasTwilioNumber = !!activeLocation.primaryRentedPhoneNumber
  const registrationStatus =
    activeLocation.registrationStatus ?? SmsRegistrationStatus.NOT_STARTED

  let preventSending =
    (!isTextingEnabled ||
      (hasTwilioNumber &&
        registrationStatus !== SmsRegistrationStatus.APPROVED)) &&
    !isFacebookTextingEnabled

  const generateDataCyAttribute = (tag: string, useLegacy = false) =>
    [
      ...(dayaCyUniquePrefix ? [dayaCyUniquePrefix] : []),
      useLegacy ? 'message-send' : 'mh-message-send',
      tag,
    ]
      .filter(Boolean)
      .join('-')

  const fileInputRef = useRef<HTMLInputElement>(null)

  const [inputText, setInputText] = useState(
    (openConversationId && activeConversations[openConversationId]) || ''
  )
  const [selectedImages, setSelectedImages] = useState<File[]>([])

  const theme = useTheme()

  const calculateTextAreaHeight = useCallback(
    (el: React.KeyboardEvent<HTMLTextAreaElement>) => {
      calculateHeight(el, !!selectedImages.length)
    },
    [calculateHeight, selectedImages]
  )
  const { showModal, closeModal } = useModalNotificationsContext()

  const onClickSend = useCallback(async () => {
    if (preventSending) {
      return
    }

    try {
      setIsSending(true)
      await onSend(inputText, selectedImages, sendVCard)
    } catch {
      // No need to do anything here, as the error is already handled upstream
    } finally {
      setIsSending(false)
      setInputText('')
      setSendVCard(false)

      if (storeActiveConversation) {
        storeActiveConversation({
          conversationId: openConversationId,
          currentText: '',
        })
      }
      setSelectedImages([])
      if (fileInputRef.current) fileInputRef.current.value = ''
    }
  }, [
    onSend,
    inputText,
    selectedImages,
    preventSending,
    storeActiveConversation,
    openConversationId,
    sendVCard,
  ])

  useEffect(() => {
    if (
      openConversationId &&
      activeConversations[openConversationId] !== inputText
    ) {
      setInputText(activeConversations[openConversationId] || '')
    }
  }, [activeConversations, openConversationId, inputText])

  useEffect(() => {
    if (!!selectedImages.length) {
      onAttach()
    }
  }, [onAttach, selectedImages])

  const handleAttachmentsClick = () => {
    if (fileInputRef.current) fileInputRef.current.click()
  }

  const onSendClick = () => {
    if (!hasTwilioNumber && !isFacebookTextingEnabled) {
      return showUpsellModal()
    }

    if (preventSending) {
      return
    }

    // TODO: Re-enable this once we have TCPA stuff in.
    const tcpaAccepted = true // hasAcceptedTCPA(legacyUser)

    if (tcpaAccepted) {
      return onClickSend()
    }

    showModal(
      getTCPAModalParams(() => {
        void refetchUser()
        closeModal()
      }, user.id)
    )
  }

  const messageSendButtonProps = getMessageSendButtonProps(
    theme,
    locationId,
    onSendClick,
    isTextingEnabled,
    isFacebookTextingEnabled,
    hasTwilioNumber ? registrationStatus : undefined
  )

  const showHelperButtons =
    isTextingEnabled && (!isInModal || activeLocation.primaryVCardId)
  const hasContentToSend = !!inputText || !!selectedImages.length
  const shouldDisableSendButton =
    isSending || !hasContentToSend || !!forceDisable

  return (
    <StyledContainer
      withPadding={withMargins && !isInModal}
      $isLargeDesktop={isExtraLargeDesktop}
      $isDesktop={isLargeDesktop}
      $isSmallDesktop={isDesktop}
      $removeMargin={isInModal}
    >
      {/* When we have more buttons, move this condition to just hide the button */}
      {showHelperButtons && (
        <HelperButtonsSection
          data-cy={generateDataCyAttribute('helper-buttons')}
          direction="row"
          height={theme.space(15)}
          flexGrow={0}
        >
          {!!activeLocation.primaryVCardId && (
            <Button
              baseDataAttribute={generateDataCyAttribute('make-a-call')}
              outline
              label="Share contact information"
              maxWidth={theme.space(sendVCard ? 64 : 59)}
              maxHeight={theme.space(8)}
              iconStyles={{
                width: theme.space(7),
                height: theme.space(7),
                fill: theme.colors.primary_2,
              }}
              endIconStyles={{
                stroke: theme.colors.primary_2,
                fill: 'none',
              }}
              icon={VCardIcon}
              endIcon={sendVCard ? CheckmarkIcon : undefined}
              onClick={() => {
                if (isTextingEnabled) {
                  setSendVCard((v) => !v)
                }
              }}
            />
          )}
          {!isInModal && (
            <Button
              baseDataAttribute={generateDataCyAttribute('make-a-call')}
              label="Call"
              maxWidth={theme.space(24)}
              maxHeight={theme.space(8)}
              icon={PhoneIcon}
              onClick={() => {
                if (isTextingEnabled) {
                  showPhoneCallModal(
                    openConversationContactDetails.id,
                    openConversationContactDetails.phoneNumber
                  )
                }
              }}
            />
          )}
        </HelperButtonsSection>
      )}

      <MessageSendContainer error={!!errorMessage}>
        <StyledTextbox
          maxLength={1000}
          data-cy={generateDataCyAttribute('textarea', true)}
          ref={textAreaRef || null}
          height={
            height ? (!!selectedImages.length ? height - 20 : height) : height
          }
          rows={1}
          placeholder="Enter your message..."
          disabled={
            forceDisable ||
            !!selectedImages.length ||
            (!isTextingEnabled && !isFacebookTextingEnabled)
          }
          onKeyUp={calculateTextAreaHeight}
          onChange={(e) => {
            onChangeTextBox && onChangeTextBox(e)

            if (storeActiveConversation) {
              storeActiveConversation({
                conversationId: openConversationId,
                currentText: e.target.value,
              })
            }
            setInputText(e.target.value)
          }}
          value={inputText}
          onFocus={(e) => onFocusTextBox && onFocusTextBox(e)}
        />

        {!!selectedImages.length && (
          <Attachments
            shouldPreventPreviews={isInModal}
            images={selectedImages.map((selectedImage) => ({
              url: URL.createObjectURL(selectedImage),
              contentType: selectedImage.type,
              name: selectedImage.name,
            }))}
            onRemoveSelectedAttachment={(idx) => {
              setSelectedImages((images) => images.filter((a, i) => i !== idx))
            }}
          />
        )}

        <MessageSendFooter>
          <AttachmentsContainer
            onClick={handleAttachmentsClick}
            data-cy={generateDataCyAttribute('attachments')}
          >
            <StyledPaperClipIcon />
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInputRef}
              multiple
              accept=".jpg,.jpeg,.png,.mp4,.mov,.m4v,.pdf"
              onChange={({ target: { files } }) => {
                const selectedFiles = Array.from(files || [])

                setSelectedImages(selectedFiles)
                fileInputRef.current!.value = ''
              }}
            />
            <Body size="medium" customColor="primary_2">
              Attachments
            </Body>
          </AttachmentsContainer>

          <HelperButtonsSection direction="row">
            <Button
              baseDataAttribute={generateDataCyAttribute('', true)}
              {...messageSendButtonProps}
              maxHeight={theme.space(8)}
              disabled={shouldDisableSendButton}
            />
          </HelperButtonsSection>
        </MessageSendFooter>
      </MessageSendContainer>

      {!!errorMessage && (
        <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
      )}
    </StyledContainer>
  )
}

export default MessageSend
