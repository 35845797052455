import { useSearchParams } from 'react-router-dom'

import { ListResourceParams, SortDirection } from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'

export const useTableSearchParams = (
  defaultTake = 50
): ListResourceParams & {
  searchParams: ReturnType<typeof useSearchParams>[0]
  setSearchParams: ReturnType<typeof useSearchParams>[1]
} => {
  const { locationId } = useLocationContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const direction = searchParams.has('direction')
    ? (searchParams.get('direction') as SortDirection)
    : undefined
  const field = searchParams.get('field') || undefined
  const search = searchParams.get('search') || undefined
  const take = searchParams.has('take')
    ? +(searchParams.get('take') as string)
    : defaultTake
  const skip = searchParams.has('skip')
    ? +(searchParams.get('skip') as string)
    : 0

  return {
    searchParams,
    setSearchParams,
    locationId,
    pagination: { take, skip },
    search,
    sort:
      direction && field
        ? {
            direction,
            field,
          }
        : undefined,
  }
}
