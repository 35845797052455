import {
  Conversation,
  ConversationEventDirection,
  ConversationEventTypeEnum,
} from 'src/api'
import { ConversationResource } from 'src/client/interfaces/ConversationsV3'
import { displayUserFriendlyMessage } from 'src/contexts/MhContext/utils'
import logger from 'src/utils/logger'

export const DEFAULT_ZERO_STATE_CONVERSATION_ID = -1

export const createZeroState = (userFirstName: string): Conversation => ({
  id: DEFAULT_ZERO_STATE_CONVERSATION_ID,
  contactDisplayName: 'Signpost',
  contactId: -1,
  isArchived: false,
  isSpam: false,
  isDeleted: false,
  mostRecentEvent: {
    id: -1,
    channelId: -1,
    isRead: true,
    isInstantResponse: false,
    timestamp: new Date().toISOString(),
    message: `${
      userFirstName ? `Hi, ${userFirstName}. ` : ''
    }Welcome to your messaging hub, a central part of your account.`,
    type: ConversationEventTypeEnum.MH_WELCOME_MESSAGE_EVENT,
    direction: ConversationEventDirection.INCOMING,
    details: {
      id: -1,
      timestamp: new Date().toISOString(),
    },
  },
})

export const countUnreadConversations = (
  conversationsList: ConversationResource[]
): number =>
  conversationsList.filter((conversation) => !conversation.isConversationRead)
    .length

export const getConversationItemPreviewErrorText = (
  conversationItem: Conversation
) => {
  const reason = conversationItem.mostRecentEvent?.errorMessage

  return reason && displayUserFriendlyMessage(reason)
}

export const playNotificationAudio = async (
  notificationAudio: HTMLAudioElement
) => {
  return notificationAudio.play().catch((err: Error) => {
    const browserConsentErrors = [
      "play() failed because the user didn't interact with the document first",
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
      'The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
    ]

    if (browserConsentErrors.some((ce) => err.message.includes(ce))) {
      logger.info(
        'LocationContext - poll - notification sound not played due to consent error'
      )
    } else {
      throw err
    }
  })
}
