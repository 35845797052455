import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface CreateContactChannelParams {
  phoneNumber?: string
  emailAddress?: string
}

export const useCreateContactChannel = (
  locationId: number,
  contactId: number
) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateContactChannelParams) => {
      const client = await getClient()

      await client.post(
        `/locations/${locationId}/contacts/${contactId}/channels`,
        {
          phoneNumber: params.phoneNumber,
          emailAddress: params.emailAddress,
        }
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['contact', { locationId, contactId }],
      })
    },
  })
}
