import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  ActiveConversationMessages,
  HandleTabConversationChangeFn,
  StoreActiveConversationParams,
} from './types'
import { canMarkConversationAsRead } from './utils'
import ContactDetailsPane from 'src/components/MessagingHub/ContactDetailsPane'
import ConversationsPane from 'src/components/MessagingHub/ConversationsPane'
import ConversationsActionsHeading from 'src/components/MessagingHub/ConversationsPane/ActionsHeading'
import MessagesPane from 'src/components/MessagingHub/MessagesPane'
import WafLayout from 'src/components/WafLayout'
import useAuthContext from 'src/contexts/AuthContext'
import { DEFAULT_ZERO_STATE_CONVERSATION_ID } from 'src/contexts/ConversationsListContext/utils'
import { useLocationContext } from 'src/contexts/LocationContext'
import useMhContext from 'src/contexts/MhContext'
import { Row } from 'src/stories/Layout'

const StyledMessagingHub = styled(Row)(({ theme }) => ({
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
  background: theme.colors.base_0,
  overflow: 'hidden',
}))

interface MessagingHubProps {
  isMultiLocation: boolean
}

const MessagingHub: React.FC<MessagingHubProps> = ({ isMultiLocation }) => {
  const authContext = useAuthContext()

  const navigate = useNavigate()

  const { search } = useLocation()
  const { locationId } = useLocationContext()

  const {
    isDesktop,
    isMediumDesktop,
    isSidedrawerOpen,

    // Conversations Pane
    conversationsListIsLoading,
    conversations,
    openConversationId,
    isZeroState,
    mutateConversationRead,

    // Messages Pane
    openConversationContactDetails,
    setOpenConversationId,
    setIsOpenConversationChangeInFlight,
  } = useMhContext()

  // Conversations
  const [conversationsTab, setConversationTab] = useState(
    new URLSearchParams(search).get('active-tab') || 'all'
  )

  // Inputs / Message Send
  const [activeConversationMessages, setActiveConversationMessages] =
    useState<ActiveConversationMessages>({})

  const [buttonState, setButtonState] = useState(false)

  const storeActiveConversation = ({
    conversationId,
    currentText,
  }: StoreActiveConversationParams) => {
    setActiveConversationMessages((prevState) => ({
      ...prevState,
      [conversationId!]: currentText,
    }))
  }

  const handleTabConversationChange: HandleTabConversationChangeFn =
    useCallback(
      (params) => {
        const newUrl = new URLSearchParams(search)
        let newQueryParam: string

        switch (params.mode) {
          case 'tab':
            if (params.value === 'editStatuses') {
              navigate(`/${locationId}/settings/messaging-hub`)

              return
            }

            newQueryParam = 'active-tab'
            setConversationTab(params.value)
            setOpenConversationId()

            break
          case 'conversation':
            newQueryParam = 'open-conversation'
            setIsOpenConversationChangeInFlight(true)
            setOpenConversationId(params.conversationId)

            if (
              params.conversationId &&
              params.conversationEventId &&
              canMarkConversationAsRead(
                authContext.user.roles,
                params.conversationId
              )
            ) {
              void mutateConversationRead({
                locationId,
                conversationId: params.conversationId,
                conversationEventId: params.conversationEventId,
                isRead: true,
              })
            }

            break
        }

        if (
          params.mode === 'conversation' &&
          params.doNavigate &&
          params.conversationId
        ) {
          newUrl.set(newQueryParam, params.conversationId.toString())
          navigate(`?${newUrl.toString()}`)
        } else if (params.mode === 'tab') {
          newUrl.set(newQueryParam, params.value.toString())
          navigate(`?${newUrl.toString()}`)
        } else {
          newUrl.delete('open-conversation')
          navigate(`?${newUrl.toString()}`)
        }
      },
      [
        search,
        setOpenConversationId,
        authContext.user.roles,
        mutateConversationRead,
        locationId,
        navigate,
        setIsOpenConversationChangeInFlight,
      ]
    )

  const handleSidedrawerState = useCallback(
    (newState: boolean) => {
      const searchParams = new URLSearchParams(search)

      searchParams.set('open-contact-profile', newState.toString())
      setButtonState(newState)
      navigate(`?${searchParams.toString()}`)
    },
    [search, navigate]
  )

  useEffect(() => {
    if (
      conversations.length >= 1 &&
      !openConversationId &&
      !conversationsListIsLoading
    ) {
      if (isMediumDesktop) {
        handleTabConversationChange({
          mode: 'conversation',
          conversationId: conversations[0].id,
          conversationEventId: conversations[0].mostRecentEvent.id,
          doNavigate:
            !isZeroState &&
            conversations[0].id !== DEFAULT_ZERO_STATE_CONVERSATION_ID,
        })
      }
    }
  }, [
    conversations,
    openConversationId,
    isZeroState,
    handleTabConversationChange,
    isMediumDesktop,
    conversationsListIsLoading,
  ])

  return (
    <WafLayout
      styleOptions={{
        withMultiLocationHeader: isMultiLocation,
        removePadding: false,
      }}
      heading={{
        titleSlot: (
          <ConversationsActionsHeading
            handleTabConversationChange={handleTabConversationChange}
            isOpenConversation={!!openConversationId}
          />
        ),
        openSidedrawerButtonLabel: 'Contact Profile',
        sidedrawerTitle: openConversationContactDetails.name || '',
        sidedrawerContent: <ContactDetailsPane />,
        openSidedrawerButtonOnClick: () => handleSidedrawerState(true),
        closeSidedrawerButtonOnClick: () => handleSidedrawerState(false),
        isSidedrawerButtonDisabled:
          openConversationId === DEFAULT_ZERO_STATE_CONVERSATION_ID ||
          buttonState,
      }}
      isSidedrawerOpen={isSidedrawerOpen}
    >
      <StyledMessagingHub
        height={`${isMediumDesktop ? '97%' : '98%'}`}
        data-cy="messaging-hub-container"
      >
        {(isMediumDesktop || (!isMediumDesktop && !openConversationId)) && (
          <ConversationsPane
            unreadConversationsCount={
              conversations.filter((c) => !c.mostRecentEvent.isRead).length
            }
            conversationsTab={conversationsTab}
            handleTabConversationChange={handleTabConversationChange}
          />
        )}
        {/* Messages / Active Conversation */}
        {(isDesktop || (!isDesktop && !!openConversationId)) && (
          <MessagesPane
            activeConversations={activeConversationMessages}
            storeActiveConversation={storeActiveConversation}
            activeConversation={conversations.find(
              (c) => c.id === openConversationId
            )}
            handleTabConversationChange={handleTabConversationChange}
          />
        )}
      </StyledMessagingHub>
    </WafLayout>
  )
}

export default MessagingHub
