import React, { useRef } from 'react'
import styled, { useTheme } from 'styled-components'

import CallsAction from './CallsAction'
import Dropdown, { StyledNavigationItem } from 'src/stories/Dropdown'
import { ChevronIcon } from 'src/stories/Icons/ChevronIcon'
import { AccountIcon, QuestionIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

const StyledAccountIcon = styled(AccountIcon)(({ theme }) => ({
  cursor: 'pointer',
  fill: theme.colors.base_0,
}))

const ChevronContainer = styled.span({
  cursor: 'pointer',
})

const StyledChevronIcon = styled(ChevronIcon)(({ theme }) => ({
  cursor: 'pointer',
  fill: theme.colors.base_0,
}))

const StyledQuestionIcon = styled(QuestionIcon)(({ theme }) => ({
  display: 'block',
  cursor: 'pointer',
}))

const AccountContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(2),
}))

const StyledDropdownInnerContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.space(2)} 0`,
}))

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(5),
  alignItems: 'center',
}))

const NavbarActions = () => {
  const { isSmallScreen, isExtraLargeScreen } = useScreenSizes()
  const chevronRef = useRef<HTMLSpanElement>(null)
  const theme = useTheme()

  return (
    <Container key="navbar-actions">
      {(isSmallScreen || isExtraLargeScreen) && <CallsAction />}
      <a
        href="https://help.signpost.com/"
        data-cy="navbar-item-support-icon"
        target="_blank"
        rel="noreferrer"
      >
        <StyledQuestionIcon fill={theme.colors.base_0} />
      </a>
      <AccountContainer>
        <StyledAccountIcon
          data-cy="navbar-item-account-icon"
          onClick={() => {
            chevronRef.current?.click()
          }}
        />
        <ChevronContainer ref={chevronRef}>
          <StyledChevronIcon direction="down" />
        </ChevronContainer>
        <Dropdown anchor={chevronRef}>
          <StyledDropdownInnerContainer data-cy="navbar-item-arrow-down-icon-dropdown-inner-container">
            <StyledNavigationItem
              to="/logout"
              data-cy="navbar-item-arrow-down-icon-inner-dropdown-item-2"
            >
              Logout
            </StyledNavigationItem>
          </StyledDropdownInnerContainer>
        </Dropdown>
      </AccountContainer>
    </Container>
  )
}

export default NavbarActions
