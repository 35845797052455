import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Conversation } from 'src/api/types'

export interface GetContactConversationsParams {
  locationId: number
  contactId?: number
}

export const useGetContactConversations = (
  { locationId, contactId }: GetContactConversationsParams,
  enabled = true
) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'contact-conversations',
      {
        locationId,
        contactId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      return getClient().then((client) =>
        client
          .get<Conversation[]>(
            `/locations/${
              params.locationId
            }/contacts/${params.contactId!}/conversations`
          )
          .then((res) => res.data)
      )
    },
    enabled,
  })
}
