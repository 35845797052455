import { flexRender } from '@tanstack/react-table'
import { format } from 'date-fns'
import Highcharts, { SeriesPieOptions } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import styled, { ThemeProvider, useTheme } from 'styled-components'

import { PieChartDataContainer } from './styled'
import { useGetInvoices, useGetBillingCycleUsage } from 'src/api'
import {
  StyledSummarySectionContainer,
  StyledWidgetBlackText,
} from 'src/components/Insights/Widgets/styled'
import PageLayout from 'src/components/WafLayout/PageLayout'
import { useBillingTable } from 'src/containers/Settings/Billing/hooks'
import { useTableSearchParams } from 'src/hooks/useTableSearchParams'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Table, TBody, Td, TFoot, Th, THead, Tr } from 'src/stories/table'
import TableSortIcon from 'src/stories/table/Table-SortIcon'
import { TablePagination } from 'src/stories/table/TablePagination'
import { Heading, Body } from 'src/stories/typography'
import { formatPhoneNumber } from 'src/utils'

const ContentContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.space(6),
  gap: theme.space(4),
}))

const HeaderContentContainer = styled.div<{ clickable: boolean }>(
  ({ clickable }) => ({
    cursor: clickable ? 'pointer' : 'default',
  })
)

const StyledZeroStateTextContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}))

const StyledChartContainer = styled.div(({ theme }) => ({
  display: 'inline-grid',
}))

const StyledChartText = styled.div<{ rowDirection: boolean }>(
  ({ theme, rowDirection }) => ({
    display: 'flex',
    flexDirection: rowDirection ? 'row' : 'column',
    borderStyle: 'solid',
    borderWidth: theme.space(0.5),
    borderColor: theme.colors.base_10,
    borderRadius: theme.constants.borderRadius,
    padding: theme.space(4),
  })
)

const StyledChartLegend = styled.div(({ theme }) => ({
  justifyContent: 'center',
  paddingTop: theme.space(8),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
}))

const Billing: React.FC = () => {
  const theme = useTheme()
  const signpostSupportEmail = process.env.REACT_APP_SIGNPOST_EMAIL!
  const signpostPhone = process.env.REACT_APP_SIGNPOST_PHONE!

  const { isSmallScreen } = useScreenSizes()

  // Route and search parameters
  const {
    setSearchParams,
    locationId,
    pagination,
    sort: sortQueryParam,
  } = useTableSearchParams(50)

  const { data: billingCycleUsageData, isLoading: isBillingLoading } =
    useGetBillingCycleUsage({ locationId })
  const { data: invoicesData, isLoading } = useGetInvoices({
    locationId,
    pagination,
    sort: sortQueryParam,
  })

  const usage = billingCycleUsageData?.usage ?? 0
  const total = billingCycleUsageData?.total ?? 0

  const sortedData = billingCycleUsageData
    ? [
        { name: 'Minutes used', y: usage },
        {
          name: 'Minutes remaining',
          y: total - usage,
        },
      ]
    : []

  const options: Highcharts.Options = {
    title: {
      text: 'Current Billing Cycle Usage',
    },
    subtitle: {
      useHTML: true,
      y: 30,
      verticalAlign: 'middle',
      floating: true,
      text: renderToStaticMarkup(
        <ThemeProvider theme={theme}>
          <PieChartDataContainer>
            <Heading size="extraLarge" customColor="primary_1">
              {((usage / (total || 1)) * 100).toFixed(0)}%
            </Heading>
            <Body as="p" color="lighter" size="large" fontWeight="medium">
              ({total} min total)
            </Body>
          </PieChartDataContainer>
        </ThemeProvider>
      ),
    },
    colors:
      usage > total
        ? [theme.colors.accent_2]
        : [theme.colors.primary_2, theme.colors.base_30],
    chart: {
      type: 'pie',
      height: 300,
      width: 300,
    },
    plotOptions: {
      pie: {
        innerSize: '80%',
        dataLabels: {
          enabled: false,
        },
        borderWidth: 3,
      },
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: true,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemMarginBottom: 8,
      useHTML: true,
      symbolRadius: 0,
    },
    series: [
      {
        name: 'value',
        colorByPoint: true,
        data: sortedData,
        type: 'pie',
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      } as SeriesPieOptions,
    ],
    accessibility: { enabled: false },
    credits: { enabled: false },
  }

  const { table, columnsCount } = useBillingTable(
    invoicesData?.data || [],
    setSearchParams,
    pagination,
    invoicesData?.total ?? 0,
    sortQueryParam
  )

  const baseDataAttribute = 'settings-billing'

  return (
    <PageLayout
      title="Live Receptionist Billing"
      baseDataAttribute={baseDataAttribute}
      subtitle={
        <>
          {' '}
          For questions about your bill, email&nbsp;
          <a href={`mailto:${signpostSupportEmail}`}>{signpostSupportEmail}</a>
          &nbsp;or give us a call at&nbsp;
          <a href={`tel:${signpostPhone}`}>
            {formatPhoneNumber(signpostPhone)}
          </a>
          &nbsp;during <br />
          business hours (Monday through Friday from 7 AM to 6 PM MST).
        </>
      }
    >
      <ContentContainer data-cy={baseDataAttribute}>
        {isBillingLoading && billingCycleUsageData ? (
          <LoadingSpinner logoHeight={theme.space(15)} />
        ) : (
          <StyledSummarySectionContainer>
            <StyledChartContainer>
              <StyledChartText rowDirection={isSmallScreen}>
                <HighchartsReact highcharts={Highcharts} options={options} />
                <StyledChartLegend>
                  <StyledWidgetBlackText lighterColor>
                    Billing Cycle:
                  </StyledWidgetBlackText>
                  <StyledWidgetBlackText>
                    {format(
                      new Date(billingCycleUsageData?.startDate || 0),
                      'PP'
                    )}
                    {' - '}
                    {format(
                      new Date(billingCycleUsageData?.endDate || 0),
                      'PP'
                    )}
                  </StyledWidgetBlackText>
                  <StyledWidgetBlackText lighterColor>
                    Plan:
                  </StyledWidgetBlackText>
                  <StyledWidgetBlackText>
                    {billingCycleUsageData?.plan}
                  </StyledWidgetBlackText>
                  <StyledWidgetBlackText lighterColor>
                    Days Left in Cycle:
                  </StyledWidgetBlackText>
                  <StyledWidgetBlackText>
                    {billingCycleUsageData?.daysLeftInCycle}
                  </StyledWidgetBlackText>
                </StyledChartLegend>
              </StyledChartText>
            </StyledChartContainer>
          </StyledSummarySectionContainer>
        )}

        <Table data-cy={baseDataAttribute + '-table'} rounded>
          <THead data-cy={baseDataAttribute + '-table-header'}>
            {table.getHeaderGroups().map((group) => (
              <Tr backgroundColor="base_10" key={group.id}>
                {group.headers.map((header) => (
                  <Th
                    key={header.id}
                    style={{ width: header.getContext().column.getSize() }}
                  >
                    {header.isPlaceholder ? null : (
                      <HeaderContentContainer
                        clickable={header.column.getCanSort()}
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <TableSortIcon isSorted={header.column.getIsSorted()} />
                      </HeaderContentContainer>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody data-cy={baseDataAttribute + '-table-body'}>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td key={cell.id} padding={4}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    )
                  })}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7}>
                  {isLoading ? (
                    <LoadingSpinner logoHeight={theme.space(15)} />
                  ) : (
                    <StyledZeroStateTextContainer>
                      No results
                    </StyledZeroStateTextContainer>
                  )}
                </Td>
              </Tr>
            )}
          </TBody>
          <TFoot data-cy={baseDataAttribute + '-table-footer'}>
            <Tr>
              <Td colSpan={columnsCount - 2} />
              <Td colSpan={2}>
                <TablePagination
                  data-cy={baseDataAttribute + '-table-footer-pagination'}
                  table={table}
                />
              </Td>
            </Tr>
          </TFoot>
        </Table>
      </ContentContainer>
    </PageLayout>
  )
}

export default Billing
