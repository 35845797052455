import { Call } from '@twilio/voice-sdk'
import { formatRelative } from 'date-fns'
import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import PhoneCallModalMessageBody from './components/PhoneCallModalMessageBody'
import { useVoiceDevice } from './hooks'
import {
  CallContainer,
  CallControlsContainer,
  Container,
  HangUpButton,
  LastMessageBodyContainer,
  LastMessageContainer,
  LastMessageHeader,
  MakeACallButton,
  MuteButton,
  PhoneCallButton,
} from './styled'
import {
  ConversationEventDirection,
  ConversationEventTypeEnum,
  isConversationEvent,
  useCreateConversationEvent,
  useGetContact,
  useGetConversationEvents,
} from 'src/api'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import Select from 'src/stories/Select'
import { Body, Heading } from 'src/stories/typography'
import { formatPhoneNumber } from 'src/utils'
import logger from 'src/utils/logger'

interface Props {
  phoneNumber: string
  callerPhoneNumber: string
  contactId?: number
}

const CallState = {
  Connecting: 'Connecting...',
  Connected: 'Connected',
  Error: 'Something went wrong. Please try again.',
  Disconnected: 'Disconnected',
  Canceled: 'Canceled',
  Reconnecting: 'Reconnecting...',
  Ringing: 'Ringing...',
} as const

const PhoneCallModalContent: React.FC<Props> = ({
  phoneNumber,
  callerPhoneNumber,
  contactId,
}) => {
  const theme = useTheme()
  const { device, isLoading } = useVoiceDevice()
  const { locationId } = useLocationContext()
  const { openConversationId } = useConversationsListContext()
  const [call, setCall] = useState<Call>()
  const [isMuted, setIsMuted] = useState(false)
  const [callState, setCallState] = useState<
    (typeof CallState)[keyof typeof CallState]
  >(CallState.Connecting)
  const { data: contact } = useGetContact({ locationId, contactId })
  const { mutateAsync: createConversationEvent } =
    useCreateConversationEvent(true)

  const { data } = useGetConversationEvents({
    locationId,
    conversationId: openConversationId,
    pagination: {
      skip: 0,
      take: 1,
    },
    query: {
      direction: ConversationEventDirection.INCOMING,
    },
  })

  const conversationEvent = data?.pages?.[0].data?.[0]

  if (isLoading || !device) {
    return <LoadingSpinner />
  }

  const clearCallDetails = () => {
    setCall(undefined)
    setCallState(CallState.Connecting)
    setIsMuted(false)
  }

  const makeACall = async () => {
    const channelId = contact?.channels?.[0]?.id

    if (!channelId || !openConversationId) {
      return
    }

    const event = await createConversationEvent({
      locationId,
      conversationId: openConversationId,
      message: '',
      channelId,
      type: ConversationEventTypeEnum.PHONE_CALL_EVENT,
      sendVCard: false,
    })

    const params: Record<string, string> = {
      To: phoneNumber,
      callType: 'outbound',
      locationId: locationId.toString(),
      callerIdPhoneNumber: callerPhoneNumber,
      currentDate: new Date().toISOString(),
    }

    if (
      event &&
      isConversationEvent(event, 'PHONE_CALL_EVENT') &&
      event.details?.id
    ) {
      params.phoneCallEventId = event.details.id.toString()
    }

    const _call = await device.connect({ params })

    _call.on('disconnect', () => {
      clearCallDetails()
      setCallState(CallState.Disconnected)
    })
    _call.on('cancel', () => {
      clearCallDetails()
      setCallState(CallState.Canceled)
    })
    _call.on('accept', () => {
      setCallState(CallState.Connected)
    })
    _call.on('error', (error: unknown) => {
      setCallState(CallState.Error)
      logger.error('Error occurred while trying to make a call', { error })
    })
    _call.on('reconnected', () => {
      setCallState(CallState.Connected)
    })
    _call.on('reconnecting', (twilioError: unknown) => {
      logger.error('Connectivity error', { twilioError })
      setCallState(CallState.Reconnecting)
    })
    _call.on('reject', () => {
      setCallState(CallState.Disconnected)
    })
    _call.on('ringing', () => {
      setCallState(CallState.Ringing)
    })
    _call.on('mute', (_isMuted: boolean) => {
      setIsMuted(_isMuted)
    })

    setCall(_call)
  }

  return (
    <Container>
      <CallContainer>
        {call ? (
          <>
            <Heading size="extraLarge" customColor="primary_2">
              {formatPhoneNumber(phoneNumber)}
            </Heading>
            <Body>{callState}</Body>
            <PhoneCallButton $pending={callState !== CallState.Connected} />
          </>
        ) : (
          <Button
            type="button"
            label={`Start Call: ${formatPhoneNumber(phoneNumber)}`}
            style={{ width: theme.space(100), height: theme.space(10) }}
            onClick={makeACall}
          />
        )}
      </CallContainer>
      {conversationEvent && (
        <LastMessageContainer>
          <LastMessageHeader>
            <Body fontWeight="bolder" color="darker">
              Last Message:
            </Body>
            <Body>
              {formatRelative(
                new Date(conversationEvent.timestamp),
                new Date()
              )}
            </Body>
          </LastMessageHeader>
          <LastMessageBodyContainer>
            <PhoneCallModalMessageBody conversationEvent={conversationEvent} />
          </LastMessageBodyContainer>
        </LastMessageContainer>
      )}
      <CallControlsContainer>
        <Select
          openedOptionsDirection="up"
          containerStyle={{ width: '25%' }}
          label="Microphone"
          options={
            device.audio?.availableInputDevices.size
              ? Array.from(device.audio?.availableInputDevices.values()).map(
                  (mediaDevice) => ({
                    label: mediaDevice.label,
                    value: mediaDevice.deviceId,
                  })
                )
              : []
          }
          onChange={async (selected: string) => {
            if (device.audio) {
              try {
                await device.audio.setInputDevice(selected)
              } catch (error) {
                logger.error(
                  'Error occurred while trying to set input device',
                  { error }
                )
              }
            }
          }}
        />
        {call && (
          <MuteButton
            $active={isMuted}
            onClick={() => {
              call.mute(!isMuted)
            }}
          />
        )}
        {call ? (
          <HangUpButton
            onClick={() => {
              call.disconnect()
              clearCallDetails()
            }}
          />
        ) : (
          <MakeACallButton onClick={makeACall} />
        )}
        <Select
          openedOptionsDirection="up"
          containerStyle={{ width: '25%' }}
          label="Speaker"
          options={
            device.audio?.availableOutputDevices.size
              ? Array.from(device.audio?.availableOutputDevices.values()).map(
                  (mediaDevice) => ({
                    label: mediaDevice.label,
                    value: mediaDevice.deviceId,
                  })
                )
              : []
          }
          onChange={async (selected: string) => {
            if (device.audio) {
              try {
                await device.audio.speakerDevices.set(selected)
              } catch (error) {
                logger.error(
                  'Error occurred while trying to set speaker device',
                  { error }
                )
              }
            }
          }}
        />
      </CallControlsContainer>
    </Container>
  )
}

export default PhoneCallModalContent
