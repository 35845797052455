import { Call, Device } from '@twilio/voice-sdk'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTheme } from 'styled-components'

import { useGetVoiceAccessToken } from 'src/api'
import PhoneCallModalContent from 'src/components/MessagingHub/PhoneCallModal'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { formatPhoneNumber } from 'src/utils'
import logger from 'src/utils/logger'

export const useShowPhoneCallModal = () => {
  const { showModal } = useModalNotificationsContext()
  const theme = useTheme()
  const { activeLocation } = useLocationContext()

  return (contactId?: number, phoneNumber?: string) => {
    if (!activeLocation.primaryRentedPhoneNumber) {
      return
    }

    if (!phoneNumber) {
      toast.error('Contact has no phone number')

      return
    }

    const show = async (callerPhoneNumber: string) => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true })
      } catch (error) {
        logger.error('Error occurred while trying to get user media', { error })

        return
      }

      showModal({
        title: `Calling from Signpost number ${formatPhoneNumber(
          callerPhoneNumber
        )}`,
        dataCy: 'mh-phone-call-modal',
        width: theme.space(240),
        hideActionButtons: true,
        customBody: (
          <PhoneCallModalContent
            contactId={contactId}
            phoneNumber={phoneNumber}
            callerPhoneNumber={callerPhoneNumber}
          />
        ),
      })
    }

    void show(activeLocation.primaryRentedPhoneNumber)
  }
}

export const useVoiceDevice = () => {
  const { locationId } = useLocationContext()
  const [device, setDevice] = useState<Device>()
  const {
    data: voiceToken,
    isLoading: isLoadingVoiceToken,
    refetch,
  } = useGetVoiceAccessToken({ locationId })

  useEffect(() => {
    if (!voiceToken?.accessToken) {
      setDevice(undefined)

      return
    }

    const _device = new Device(voiceToken.accessToken, {
      logLevel: 'warn',
      // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
      // providing better audio quality in restrained network conditions.
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
    })

    _device.on('error', (err: unknown) => {
      logger.error('Error occurred in Twilio Voice SDK', { err })
    })

    _device.audio?.on('deviceChange', (newAudioDevice: string) => {
      logger.info('Audio device changed', { newAudioDevice })
    })

    _device.on('tokenWillExpire', async () => {
      await refetch()
    })

    void _device
      .register()
      .then(() => {
        setDevice(_device)
      })
      .catch((error: unknown) => {
        setDevice(undefined)

        logger.error(
          'Error occurred while trying to register Twilio Voice SDK',
          { error }
        )
      })

    return () => {
      _device.destroy()
      setDevice(undefined)
    }
  }, [voiceToken?.accessToken, refetch])

  return { device, isLoading: isLoadingVoiceToken }
}
