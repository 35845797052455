import React from 'react'
import styled, { useTheme } from 'styled-components'

import { useGetLocationForwardingPhoneNumber } from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'
import { useLocationForwardingPhoneNumber } from 'src/hooks/useLocationForwardingPhoneNumber'
import { ReceptionistIcon } from 'src/stories/assets'
import { Body } from 'src/stories/typography'

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(3),
  backgroundColor: theme.colors.primary_2,
  borderRadius: theme.constants.borderRadius,
  alignItems: 'center',
}))

const ItemContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.space(2),
  padding: `${theme.space(2)} ${theme.space(3)}`,
  position: 'relative',

  ':after': {
    content: '""',
    position: 'absolute',
    right: theme.space(-1.5),
    paddingTop: theme.space(1),
    paddingBottom: theme.space(1),
    height: '75%',
    top: 'calc(12.5%)', // (100% - height) / 2
    bottom: 0,
    backgroundColor: theme.colors.base_0_20,
    width: theme.space(0.5),
  },
  ':nth-last-child(-n+2):after': {
    display: 'none',
  },
}))

const LrToggleContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.base_0,
  paddingRight: theme.space(1),
  paddingLeft: theme.space(1),
  borderRadius: theme.constants.borderRadius,
  position: 'relative',
  display: 'inline-flex',
  minWidth: theme.space(12),
  height: '100%',
  gap: theme.space(2),
}))

const LrToggleTrack = styled.span<{ disabled: boolean; selected: boolean }>(
  ({ theme, disabled, selected }) => ({
    position: 'absolute',
    cursor: disabled ? 'auto' : 'pointer',
    backgroundColor: theme.colors.accent_3,
    borderRadius: theme.constants.borderRadius,
    height: `calc(100% - ${theme.space(2)})`,
    top: theme.space(1), // (100% - height) / 2
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: disabled ? 0.5 : 1,
    /**
     * 21 = 14 (width of label) + 2 (gap between labels) + 3 (margin of label [one side]) + 2 (padding of wrapper container [both sides])
     */
    transform: `translateX(${selected ? '0px' : theme.space(21)})`,
    transition: 'transform 0.3s ease',
  })
)

const LrToggleLabel = styled.span(({ theme }) => ({
  margin: `${theme.space(2.25)} ${theme.space(3)}`,
  width: theme.space(14),
  textAlignLast: 'center',
  cursor: 'pointer',
}))

const StyledBody = styled(Body)(({ theme }) => ({
  transition: 'color 0.3s ease',
}))

const CallsAction = () => {
  const theme = useTheme()
  const { locationId } = useLocationContext()
  const { data: forwardingPhoneNumber } = useGetLocationForwardingPhoneNumber({
    locationId,
  })
  const { isLrEnabled, isTogglingDisabled, onToggle, isTextingEnabled } =
    useLocationForwardingPhoneNumber({
      forwardingPhoneNumber,
      canDoToggle: () => Promise.resolve(true),
    })

  const props = {
    disabled: isTogglingDisabled,
    selected: isLrEnabled,
  }

  if (!isTextingEnabled) {
    return null
  }

  return (
    <Container data-cy="calls-action">
      {/* <ItemContainer>
        <PhoneIcon fill={theme.colors.base_0} />
        <Body customColor="base_0" size="large">
          Make a call
        </Body>
      </ItemContainer> */}
      <ItemContainer>
        <ReceptionistIcon fill={theme.colors.base_0} />
        <Body customColor="base_0" size="large">
          Live Receptionist
        </Body>
      </ItemContainer>
      <LrToggleContainer>
        <LrToggleLabel onClick={onToggle}>
          <StyledBody
            customColor={isLrEnabled ? 'base_0' : 'accent_3'}
            size="large"
          >
            Active
          </StyledBody>
        </LrToggleLabel>
        <LrToggleLabel onClick={onToggle}>
          <StyledBody
            customColor={isLrEnabled ? 'accent_3' : 'base_0'}
            size="large"
          >
            Inactive
          </StyledBody>
        </LrToggleLabel>

        <LrToggleTrack {...props} onClick={onToggle}>
          <LrToggleLabel>
            <Body customColor="base_0" size="large">
              {isLrEnabled ? 'Active' : 'Inactive'}
            </Body>
          </LrToggleLabel>
        </LrToggleTrack>
      </LrToggleContainer>
    </Container>
  )
}

export default CallsAction
